// extracted by mini-css-extract-plugin
export var benefits = "EvocativeServicesOverview__benefits__IPWZn";
export var column = "EvocativeServicesOverview__column__BZroO";
export var flex = "EvocativeServicesOverview__flex__B859r";
export var flexColumn = "EvocativeServicesOverview__flexColumn___PlRQ";
export var gap1 = "EvocativeServicesOverview__gap1__MSMlk";
export var gap2 = "EvocativeServicesOverview__gap2__t6Wi_";
export var gap3 = "EvocativeServicesOverview__gap3__tkqg6";
export var gap4 = "EvocativeServicesOverview__gap4__uu4n2";
export var gap5 = "EvocativeServicesOverview__gap5__S75g6";
export var row = "EvocativeServicesOverview__row___HDtN";
export var service = "EvocativeServicesOverview__service__agoSs";
export var serviceDetails = "EvocativeServicesOverview__serviceDetails__RY2WV";
export var servicesOverview = "EvocativeServicesOverview__servicesOverview__yxLW3";