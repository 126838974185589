// extracted by mini-css-extract-plugin
export var column = "ExclusiveServices__column__GVkfZ";
export var description = "ExclusiveServices__description__tvOSz";
export var flex = "ExclusiveServices__flex__klHYv";
export var flexColumn = "ExclusiveServices__flexColumn__yCFsd";
export var gap1 = "ExclusiveServices__gap1__s7RPr";
export var gap2 = "ExclusiveServices__gap2__hIcfa";
export var gap3 = "ExclusiveServices__gap3__ki61F";
export var gap4 = "ExclusiveServices__gap4__Y35nW";
export var gap5 = "ExclusiveServices__gap5__Vrfm5";
export var icon = "ExclusiveServices__icon__q_i6a";
export var link = "ExclusiveServices__link__xYTlb";
export var row = "ExclusiveServices__row___rPED";
export var service = "ExclusiveServices__service__zMSJG";
export var serviceBlock = "ExclusiveServices__serviceBlock__kAVwL";
export var services = "ExclusiveServices__services__tTMyw";
export var title = "ExclusiveServices__title__r6J2d";
export var wrapper = "ExclusiveServices__wrapper__M_DLl";