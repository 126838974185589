// extracted by mini-css-extract-plugin
export var anchor = "EvocativeBareMetal__anchor__pFOlp";
export var backgroundImage = "EvocativeBareMetal__backgroundImage__hjXxo";
export var cloudProviderIconsBareMetalSection = "EvocativeBareMetal__cloudProviderIconsBareMetalSection___nI4T";
export var column = "EvocativeBareMetal__column__jVEbi";
export var completeControlWithBareMetalSection = "EvocativeBareMetal__completeControlWithBareMetalSection__hgeoz";
export var detailsContent = "EvocativeBareMetal__detailsContent__K9r8b";
export var detailsSection = "EvocativeBareMetal__detailsSection__DrVaL";
export var detailsSidebar = "EvocativeBareMetal__detailsSidebar__Nnot0";
export var flex = "EvocativeBareMetal__flex__hi5eo";
export var flexColumn = "EvocativeBareMetal__flexColumn__HFBL4";
export var gap1 = "EvocativeBareMetal__gap1__a9x0N";
export var gap2 = "EvocativeBareMetal__gap2__Os6lm";
export var gap3 = "EvocativeBareMetal__gap3__RUZHy";
export var gap4 = "EvocativeBareMetal__gap4__lmZT3";
export var gap5 = "EvocativeBareMetal__gap5__vETv4";
export var jumbotronSection = "EvocativeBareMetal__jumbotronSection__q61PM";
export var map = "EvocativeBareMetal__map__Rwtjg";
export var pricingAndConfigurationsSection = "EvocativeBareMetal__pricingAndConfigurationsSection__KyVVp";
export var pricingAndConfigurationsSectionContainer = "EvocativeBareMetal__pricingAndConfigurationsSectionContainer__MNnJl";
export var requestEstimateBareMetalSection = "EvocativeBareMetal__requestEstimateBareMetalSection__Ehq_0";
export var row = "EvocativeBareMetal__row__QY8LE";
export var signUpTodaySection = "EvocativeBareMetal__signUpTodaySection__jpLTR";
export var sticky = "EvocativeBareMetal__sticky__uPAV1";
export var subNavigationBareMetalSection = "EvocativeBareMetal__subNavigationBareMetalSection__l1qYQ";
export var whyBareMetalSection = "EvocativeBareMetal__whyBareMetalSection__ORBKa";
export var wideContainer = "EvocativeBareMetal__wideContainer__MmyoV";
export var wrapper = "EvocativeBareMetal__wrapper__efE3K";