// extracted by mini-css-extract-plugin
export var column = "ResourcesSidebarSection__column__qPDcR";
export var flex = "ResourcesSidebarSection__flex__9qFcT";
export var flexColumn = "ResourcesSidebarSection__flexColumn__OIgeg";
export var gap1 = "ResourcesSidebarSection__gap1__Wki8H";
export var gap2 = "ResourcesSidebarSection__gap2__Xb8vH";
export var gap3 = "ResourcesSidebarSection__gap3__xCSBu";
export var gap4 = "ResourcesSidebarSection__gap4__YSkEV";
export var gap5 = "ResourcesSidebarSection__gap5__d2UaF";
export var imageContainer = "ResourcesSidebarSection__imageContainer__M3opi";
export var resource = "ResourcesSidebarSection__resource__n16fn";
export var resourceImage = "ResourcesSidebarSection__resourceImage__MlODn";
export var resourceTitle = "ResourcesSidebarSection__resourceTitle__OwQ1s";
export var resources = "ResourcesSidebarSection__resources__s54MM";
export var resourcesLink = "ResourcesSidebarSection__resourcesLink__Numdv";
export var row = "ResourcesSidebarSection__row__EwnD2";
export var wrapper = "ResourcesSidebarSection__wrapper__jkhA8";