// extracted by mini-css-extract-plugin
export var cell = "EvocativeBareMetalPricing__cell__YZdZX";
export var cellGray = "EvocativeBareMetalPricing__cellGray__SwI09";
export var column = "EvocativeBareMetalPricing__column__kURZs";
export var desktop = "EvocativeBareMetalPricing__desktop__hOgg3";
export var flex = "EvocativeBareMetalPricing__flex__GDLzd";
export var flexColumn = "EvocativeBareMetalPricing__flexColumn__yNdwG";
export var gap1 = "EvocativeBareMetalPricing__gap1__B4z4n";
export var gap2 = "EvocativeBareMetalPricing__gap2__RrgL3";
export var gap3 = "EvocativeBareMetalPricing__gap3__pOhPc";
export var gap4 = "EvocativeBareMetalPricing__gap4__lAaXi";
export var gap5 = "EvocativeBareMetalPricing__gap5__TlVi2";
export var headerCell = "EvocativeBareMetalPricing__headerCell__oR86K";
export var headerRow = "EvocativeBareMetalPricing__headerRow__c18jF";
export var heading = "EvocativeBareMetalPricing__heading__wnDM2";
export var horizontalScrollRow = "EvocativeBareMetalPricing__horizontalScrollRow__PmsyP";
export var large = "EvocativeBareMetalPricing__large__l2rIV";
export var medium = "EvocativeBareMetalPricing__medium__T5SWf";
export var meta = "EvocativeBareMetalPricing__meta__Jc6Mn";
export var metaCell = "EvocativeBareMetalPricing__metaCell__ZwIKN";
export var metaFields = "EvocativeBareMetalPricing__metaFields__xMFpA";
export var mobile = "EvocativeBareMetalPricing__mobile__FOd2G";
export var mobileOnlyMeta = "EvocativeBareMetalPricing__mobileOnlyMeta__FakDw";
export var price = "EvocativeBareMetalPricing__price__C1Mjv";
export var pricingCell = "EvocativeBareMetalPricing__pricingCell__as_eW";
export var pricingRow = "EvocativeBareMetalPricing__pricingRow__PwYfb";
export var pricingTable = "EvocativeBareMetalPricing__pricingTable__wz05D";
export var row = "EvocativeBareMetalPricing__row__HbH29";
export var small = "EvocativeBareMetalPricing__small__LOEJ9";
export var spacer = "EvocativeBareMetalPricing__spacer__ARpif";
export var subheading = "EvocativeBareMetalPricing__subheading__t8LK_";
export var title = "EvocativeBareMetalPricing__title__m37pu";
export var wrapper = "EvocativeBareMetalPricing__wrapper__CS46K";