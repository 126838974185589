// extracted by mini-css-extract-plugin
export var bannerContainer = "EvocativeCaseStudyBanner__bannerContainer__dFOoT";
export var column = "EvocativeCaseStudyBanner__column__ln8F2";
export var ctaWrapper = "EvocativeCaseStudyBanner__ctaWrapper__nzdzm";
export var description = "EvocativeCaseStudyBanner__description__S2x3U";
export var flex = "EvocativeCaseStudyBanner__flex__Z1LVu";
export var flexColumn = "EvocativeCaseStudyBanner__flexColumn__Sg6IQ";
export var gap1 = "EvocativeCaseStudyBanner__gap1__q0AKm";
export var gap2 = "EvocativeCaseStudyBanner__gap2__DINhR";
export var gap3 = "EvocativeCaseStudyBanner__gap3__lfGWI";
export var gap4 = "EvocativeCaseStudyBanner__gap4__SdD3J";
export var gap5 = "EvocativeCaseStudyBanner__gap5__ICDpk";
export var row = "EvocativeCaseStudyBanner__row__oTugc";
export var testimonialText = "EvocativeCaseStudyBanner__testimonialText__WoMnu";
export var testimonialWrapper = "EvocativeCaseStudyBanner__testimonialWrapper__iQjVc";
export var title = "EvocativeCaseStudyBanner__title__sGluu";
export var wrapper = "EvocativeCaseStudyBanner__wrapper__N9duw";
export var wrapperBackgroundImage = "EvocativeCaseStudyBanner__wrapperBackgroundImage__votoc";
export var wrapperContainer = "EvocativeCaseStudyBanner__wrapperContainer__IicEl";