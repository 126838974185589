// extracted by mini-css-extract-plugin
export var column = "JumbotronEvocative__column__L9Ck3";
export var flex = "JumbotronEvocative__flex__MhE6I";
export var flexColumn = "JumbotronEvocative__flexColumn__nTmTb";
export var gap1 = "JumbotronEvocative__gap1__i7tyV";
export var gap2 = "JumbotronEvocative__gap2__qzSJa";
export var gap3 = "JumbotronEvocative__gap3__XqbTm";
export var gap4 = "JumbotronEvocative__gap4__Pb6G2";
export var gap5 = "JumbotronEvocative__gap5__DVZDw";
export var heading = "JumbotronEvocative__heading__a3DTr";
export var icon = "JumbotronEvocative__icon__dHlc7";
export var row = "JumbotronEvocative__row__QpsP2";
export var subheading = "JumbotronEvocative__subheading__bHKyD";
export var wrapper = "JumbotronEvocative__wrapper__rolDn";